const contentRu = {
  nav1: 'Меню',
  about: 'О нас',
  brunch: 'Бранч',
  banquet: 'Банкеты',
  contacts: 'Контакты',
  flag1: 'Latvian',
  flag2: 'English',
  hero1: 'Здравствуйте',
  hero2: 'House of light',
  res_button: 'Резервировать столик',
  about_title:
    'Добро пожаловать в “House of Light’’ – уютный ресторан в самом сердце Юрмалы!',
  about1:
    'Мы создали место где вы можете отдохнуть от повседневной суеты ,насладиться прекрасной кухней и освежающими напитками.',
  about2:
    'В наше обширное меню входят различные свежие салаты, закуски, деликатесные платы, отменные блюда из морепродуктов, а также вкуснейшие десерты. Мы предлогаем также вина латвийского производства и обжаренный в Латвии кофе.',
  about3:
    'Наш ресторан рад приветсвовать семьи с маленькими детьми, так как мы позаботились о специальном детском меню – разукрашке, детском развлекательном уголке как внутри, так и на улице, а также о пеленальном столике со всем необходимым для самых маленьких.',
  brunch_title: 'Воскресный бранч',
  brunch_about:
    'Хотя мы еще не можем предоставить наши обычные бранчи, учитывая высокий спрос на поздний завтрак, мы создали новую концепцию - «Мини бранч», которая позволяет нам позаботиться о вашей безопасности, накрывая стол именно для вас!',
  brunch_list: [
    'Каждуoe воскресенье с 12:00 до 15:00.',
    'Для групп от 6 человек',
    'Новое предложение каждую неделю',
    '20 € на человека',
    'Детдям до 6 лет бесплатно, от 7 лет - 10 €',
    'В стоимость входит бокал просекко или глинтвейна, вода, морс, кофе и чай.',
  ],
  banquet_title: 'Банкетные предложения',
  banquet_subtitle: 'У вас праздник – мы об этом позаботимся!',
  banquet1:
    ' У нас можно заказать все, начиная от аппетитных закусочных плат, свежих салатов, разнообразния горячих блюд и заканчивая домашними лемонадами и другими напитками.',
  banquet2: 'Обеспечиваем доствку еды',
  banquet3: 'Предлагаем обслуживающий персонал для вашего мероприятия',
  banquet4: 'Для получения более подробной информации писать на почту',
  banquet_email: 'raitis@hol.lv',
  reserve_title: 'Резервировать',
  reserve_name: 'Имя',
  reserve_name_placeholder: 'Михаил',
  reserve_date: 'Дата',
  reserve_time: 'Время',
  reserve_count: 'Количество гостей',
  reserve_phone: 'Номер телефона',
  reserve_comments: 'Коментарии',
  reserve_comments_placeholder:
    'Необходим десткий стульчик или допольнительные пожелания',
  working_hours: 'Время работы.',
  work_days: 'Пн. – Вс.',
  rights: 'SIA House of Light,  все права защищены',
}

export default contentRu
